.new {
  background-image: url("../../images/labels/new-labels/new.png");

  &.tag {
    width: 37px;
    height: 17px;
  }
}

// .recomand{
//     background-image: url('../../images/labels/new-labels/recomand.png');
// }
.discount {
  background-image: url("../../images/labels/new-labels/discount.png");

  &.tag {
    width: 54px;
    height: 17px;
  }
}

.count-number {
  font-size: 16px;
  font-weight: 700;
}

.sales {
  background-image: url("../../images/labels/new-labels/sales.png");

  &.tag {
    width: 37px;
    height: 17px;
  }
}

.week {
  background-image: url("../../images/labels/new-labels/week.png");

  &.tag {
    width: 59px;
    height: 17px;
  }
}

.product-price {
  display: flex;
  justify-content: space-evenly;

  .price,
  .price-before-discount {
    line-height: 16px !important;
  }

  .price {
    color: #343434;
    margin-bottom: 10px;
  }
}

.percent-discount {
  border: 2px solid #000;
  margin: 0px 5px 5px;
  border-radius: 10px;
  background: #e6e6e6;
  padding: 0px 10px;
  font-weight: 700;
  border-top: none;
  border-right: none;
  height: 20px;
  color: #000;
  position: absolute;
  top: 0px;
  right: 0px;
}

.hot-deals .product-info .name {
  min-height: 30px;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}

.add-to-cart {
  background-color: #e95a0e;
  border-radius: 0px 0px 10px 10px;
}
.product-type {
  // margin: 5px 0px 0px 5px;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  text-transform: capitalize;
  span {
    border: 1px solid #686868;
    margin-top: 5px;
    display: inline-block;
    border-radius: 10px;
    padding: 0px 5px;
  }
}
.product-info {
  border-top: 1px solid #e95a0e;
}
.expert-color {
  background-color: #fff;
  padding-top: 1px;
  .product-type,
  .name,
  .price,
  .price-before-discount {
    color: #000 !important;
  }
}
.comfortmat-color {
  background-color: #e7c700;
  padding-top: 1px;
  .product-type,
  .name,
  .price,
  .price-before-discount {
    color: #000 !important;
  }
}
.premium-color {
  background-color: #000000;
  padding-top: 1px;
  .product-type,
  .name a,
  .price,
  .price-before-discount {
    color: #fff !important;
  }
}
.products {
  margin-right: -1px;
  margin: 5px;
  border-radius: 12px;
  // min-height: 350px;
  display: flex;
  background: #fff;
  align-items: center;
  border: 1px solid #e95a0e;

  .product {
    width: 100%;

    &:hover {
      // box-shadow: -1px 1px 10px 10px rgba(19, 148, 55, 0.3);
    }
  }

  .product-description {
    color: #343434;
    text-align: right;
    min-height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .popup-description {
    cursor: pointer;

    &:hover {
      color: #ff3939;
    }
  }

  .show-description-popup {
    color: #343434;
    background-color: rgba(000, 000, 000, 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .product-image {
    position: relative;
  }

  .close-icon {
    font-size: 20px;
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 10px;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      color: #a00006;
    }
  }
}
